.sale-helper-wrapper {
    width: min-content;
    margin-top: 24px;
}
.sale-helper-block {
    display: flex;
    gap: 20px;
    align-items: baseline;
}
.sale-helper-block button {
    height: fit-content;
    white-space: nowrap;
    padding: 6px 32px;
}
.sale-instructions {
    display: flex;
    background-color: var(--header-text-color);
    padding: 4px 8px;
    gap: 8px;
    align-items: center;
    margin-top: 8px;
    border-radius: 4px;
}
.sale-instructions svg {
    flex-shrink: 0;
}
.sale-instructions p {
    margin: 0;
    font-size: 13px;
    color: var(--symbol-gray);
    line-height: normal;
}
.datepicker-main-wrapper{
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    gap: 20px;
}
.datepicker-wrapper{
    display: flex;
    width: fit-content;
    flex-direction: column;
    margin: 8px 0;
}
.datepicker-subwrapper{
    position: relative;
}
div.react-datepicker-wrapper{
    width: 206px;
}
.react-datepicker__input-container input{
    display: block;
    padding: 0.375rem 0.75rem;
    width: inherit;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: #212529;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #ced4da;
    appearance: none;
    border-radius: 4px;
    transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out;
}
.react-datepicker__input-container input.non-editable-mode{
    background: var(--header-text-color);
    pointer-events: none;
}
.datepicker-wrapper.date .react-datepicker__input-container input{
    width: 168px;
    border-radius: 4px 0 0 4px;
}
.react-datepicker__input-container input:focus {
    color: #212529;
    background-color: #fff;
    border-color: #86b7fe;
    outline: 0;
    box-shadow: 0 0 0 0.25rem rgb(13 110 253 / 25%);
}
div.react-datepicker__input-container{
    display: flex;
}
button.react-datepicker__close-icon{
    position: static;
    height: auto;
    padding: 0;
    width: 38px;
    background: var(--bg-button-gray);
    border-top-right-radius: 4px;
    border-bottom-right-radius: 4px;
    border: 1px solid var(--gray-border);
    border-left: none;
    align-items: center;
    display: flex;
    justify-content: center;
    color: var(--symbol-gray);
}
button.react-datepicker__close-icon:after{
    display: inline-table;
    background: transparent;
    content: url('data:image/svg+xml,<svg width="11" height="10" viewBox="0 0 11 10" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M0.545147 0.209209C0.824093 -0.0697365 1.27635 -0.0697365 1.5553 0.209209L5.33594 3.98985L9.11658 0.209209C9.39552 -0.0697365 9.84778 -0.0697365 10.1267 0.209209C10.4057 0.488155 10.4057 0.940416 10.1267 1.21936L6.34609 5L10.1267 8.78064C10.4057 9.05958 10.4057 9.51184 10.1267 9.79079C9.84778 10.0697 9.39552 10.0697 9.11658 9.79079L5.33594 6.01015L1.5553 9.79079C1.27635 10.0697 0.824093 10.0697 0.545147 9.79079C0.266201 9.51184 0.266201 9.05958 0.545147 8.78064L4.32579 5L0.545147 1.21936C0.266201 0.940416 0.266201 0.488155 0.545147 0.209209Z" fill="rgb(108, 117, 125)"/></svg>');
}
button.react-datepicker__close-icon.disabled:after{
    background: transparent;
    content: url('data:image/svg+xml,<svg width="17" height="16" viewBox="0 0 17 16" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M3.66797 0C3.94411 0 4.16797 0.223858 4.16797 0.5V1H12.168V0.5C12.168 0.223858 12.3918 0 12.668 0C12.9441 0 13.168 0.223858 13.168 0.5V1H14.168C15.2725 1 16.168 1.89543 16.168 3V14C16.168 15.1046 15.2725 16 14.168 16H2.16797C1.0634 16 0.167969 15.1046 0.167969 14V3C0.167969 1.89543 1.0634 1 2.16797 1H3.16797V0.5C3.16797 0.223858 3.39183 0 3.66797 0ZM2.16797 2C1.61568 2 1.16797 2.44772 1.16797 3V4H15.168V3C15.168 2.44772 14.7203 2 14.168 2H2.16797ZM15.168 5H1.16797V14C1.16797 14.5523 1.61568 15 2.16797 15H14.168C14.7203 15 15.168 14.5523 15.168 14V5Z" fill="rgb(108, 117, 125)"/></svg>');
}
button.react-datepicker__close-icon:not(.disabled){
    z-index: 2;
}
button.react-datepicker__close-icon.disabled{
    position: absolute;
    height: 38px;
}


.sales-wrapper{
    padding: 24px 20px;
    background-color: var(--bg-sales);
    border: 1px solid var(--bs-gray);
    border-radius: 5px;
    margin: 28px 0;
}
button.addRemoveSalesBtn{
    width: 204px;
    margin-top: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10px;
}
button.addRemoveSalesBtn>a{
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10px;
    text-decoration: none;
}
div.react-select__menu-list{
    max-height: 165px;
    overflow: auto;
}
div.react-select__option{
    cursor: pointer;
}
div.react-select__menu-list::-webkit-scrollbar {
    width: 6px;
    /*padding: 4px;*/
}
div.react-select__menu-list::-webkit-scrollbar-thumb {
    background: var(--track-bg);
    border-radius: 10px;
}
div.react-select__option:hover,
div.react-select__option--is-focused,
div.react-select__option--is-selected{
    background-color: var(--bs-light);
    color: black;
}
div.react-select__option{
    background-color: white;
}
div.react-select__control{
    border-color: var(--gray-border);
    box-shadow: none;
    cursor: pointer;
}
div.is-invalid>div.react-select__control{
    border-color: var(--bs-red);
}
div.prices-block div.react-select__control{
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
}
div.non-editable-mode div.react-select__control{
    background: var(--header-text-color);
    pointer-events: none;
}
div.react-select__control:hover{
    border-color: var(--gray-border);
}
span.react-select__indicator-separator{
    display: none;
}
.sales-horizontal-line{
    margin: 16px 0;
}