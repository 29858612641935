@keyframes removed-item-animation {
    0% {
        opacity: 1;
        transform: translateX(0);
    }

    30% {
        opacity: 1;
        transform: translateX(50px);
    }

    80% {
        opacity: 1;
        transform: translateX(-800px);
    }

    100% {
        opacity: 0;
        transform: translateX(-800px);
    }
}

@keyframes openspace {
    from {
        opacity: 0;
    }
    to {
        opacity: 0;
    }
}

@keyframes restored-item-animation {
    0% {
        opacity: 0;
        transform: translateX(300px);
    }

    70% {
        opacity: 1;
        transform: translateX(-50px);
    }

    100% {
        opacity: 1;
        transform: translateX(0);
    }
}