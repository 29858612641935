.card-wrapper{
    display: flex;
    gap: 32px;
    padding: 32px 0;
}
.card-wrapper a{
    text-decoration: none;
    color: inherit;
}
.card-block{
    min-width: 260px;
    background-color: var(--header-text-color);
    min-height: 125px;
    align-items: center;
    justify-content: center;
    border-radius: 8px;
    border: none;
    box-shadow: 0px 4px 6px 0px #2C2C2C40;
}
.card-title{
    margin: 0;
    font-weight: 600;
}
.sale-info-wrapper{
    display: flex;
    gap: 36px;
}