input.inappInput{
    max-width: 875px;
}
.warning-block{
    font-weight: 500;
    display: flex;
    gap: 10px;
    margin: 24px 0 0;
}
.inputs-wrapper{
    display: flex;
}
.left-form-control{
    max-height: 38px;
    border-top-left-radius: 4px;
    border-bottom-left-radius: 4px;
    background-color: var(--bg-button-gray);
    border: 1px solid var(--bs-gray-border);
    border-right: 0;
    margin-bottom: 0;
    padding: 0 12px;
}
.right-form-control{
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
    min-width: 390px;
}
.input-block{
    width: 100%;
}
.inapp-select {
    width: 100%;
}
.inapp-id-wrapper{
    flex: 1;
    max-width: 800px;
}