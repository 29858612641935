#button-tooltip{
    position: absolute;
    top: 0;
    filter: drop-shadow(0px 3px 7px rgba(0, 0, 0, 0.2)) drop-shadow(0px 0px 1px rgba(0, 0, 0, 0.25));
}
.tooltip-overlay > .tooltip-inner{
    background: var(--bs-white);
    color: var(--bs-dark);
    border: 1px solid var(--bs-white);
}
.tooltip-overlay > .tooltip-arrow:before {
    border-top-color: var(--bs-white);
}