.tier-currency-wrapper{
    display: flex;
    gap: 24px;
    margin: 24px 16px;
}
.currency-block{
    color: var(--symbol-gray);
    text-align: center;
}
.currency-item{
    display: flex;
    flex-direction: column;
    gap: 6px;
    align-items: center;
}
.warning-wrapper{
    display: flex;
    align-items: center;
    padding: 8px 12px;
    width: fit-content;
    gap: 8px;
    background: var(--header-text-color);
    border: 1px solid var(--bs-gray);
    border-radius: 4px;
}