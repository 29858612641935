input.formControl{
    border-radius: 0.25rem;
}
.inputWrapper{
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 20px;
}
.inputGroup{
    display: flex;
    align-items: center;
    width: 100%;
    gap: 20px;
}
.suggestionBlock{
    max-width: 630px;
    width: 100%;
    position: relative;
}
.filter{
    max-width: 520px;
}
.dropdownMenu{
    width: 100%;
    margin-top: 8px;
    max-height: 70vh;
    overflow: auto;
    border: 1px solid var(--track-bg);
    box-shadow: 0px 2px 2px rgb(0 0 0 / 25%);
    border-radius: 4px;
}
.dropdownMenu::-webkit-scrollbar {
    width: 6px;
    background: var(--bs-white);
}
.dropdownMenu::-webkit-scrollbar-thumb {
    background: var(--track-bg);
    border-radius: 10px;
}
