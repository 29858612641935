div.filter-btn-group{
    gap: 15px;
}
button.filter-btn{
    padding: 6px 14px;
    border-radius: 24px !important;
    color: var(--bs-dark) !important;
    font-size: 13px;
}
button.filter-btn:active:focus,
button.filter-btn:focus{
    box-shadow: none;
}
button.filter-btn.filter-allstatuses{
    border-color: var(--track-bg);
}
button.filter-btn.filter-allstatuses:hover,
button.filter-btn.filter-allstatuses.selected{
    background-color: var(--track-bg);
}

button.filter-btn.filter-onreview{
    border-color: var(--bg-status-review);
}
button.filter-btn.filter-onreview:hover,
button.filter-btn.filter-onreview.selected{
    background-color: var(--bg-status-review);
}

button.filter-btn.filter-ready{
    border-color: var(--bg-status-ready);
}
button.filter-btn.filter-ready:hover,
button.filter-btn.filter-ready.selected{
    background-color: var(--bg-status-ready);
}

button.filter-btn.filter-active{
    border-color: var(--bg-status-active);
}
button.filter-btn.filter-active:hover,
button.filter-btn.filter-active.selected{
    background-color: var(--bg-status-active);
}