.authWrapper{
    display: flex;
    background: var(--block-background-gray);
    justify-content: center;
    align-items: center;
    flex-direction: column;
    gap: 16px;
    flex: 1;
}
.authText{
    color: var(--symbol-gray);
}
.authBtn{
    display: flex;
    align-items: center;
    gap: 10px;
}