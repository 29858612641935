div.customSelect{
    width: 165px;
}
.selectBlock{
    display: flex;
    margin: 4px 0;
}
.selectBlock.is-invalid > .prices-block > .react-select__control{
    border-color: var(--bs-red);
}
.labelBlock{
    width: 42px;
    background: var(--bg-button-gray);
    border-top-right-radius: 4px;
    border-bottom-right-radius: 4px;
    border: 1px solid var(--gray-border);
    border-left: none;
    align-items: center;
    display: flex;
    justify-content: center;
    color: var(--symbol-gray);
}


div.customSelect.inapps-list{
    width: 196px;
}