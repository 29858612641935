.loadingWrapper{
    display: flex;
    height: calc(100% - 75px);
    justify-content: center;
    align-items: center;
    flex-direction: column;
    gap: 16px;
}
.loadingWrapperGray{
    background: var(--block-background-gray);
}
.loadingWrapperTransparent{
    background: transparent;
}
.loadingText{
    color: var(--symbol-gray);
}
.spinnerWrapper{
    width: 19px;
    height: 19px;
    border-width: .15em;
}