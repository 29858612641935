.modalFooter{
    justify-content: space-between;
}
.modalFooterFlexEnd{
    justify-content: end;
}
.modalTitle{
    font-weight: 600;
    font-size: 19px;
}
.modalCheckboxWrapper{
    column-count: 2;
}
.modalBtn{
    max-height: 38px;
    display: flex;
    align-items: center;
}
.spinnerWrapper{
    width: 19px;
    height: 19px;
    border-width: .15em;
    margin: 0 60px;
}