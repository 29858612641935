.navigationBar{
    width: max-content;
    display: flex;
    flex-direction: column;
    gap: 16px;
    position: sticky;
    top: 30px;
}
.navigationBar a{
    text-decoration: none;
}
.navigationBar a.active{
    font-weight: 600;
}