.subheader{
    display: flex;
    gap: 10px;
    /*margin: 10px 0;*/
}
.subheader > h1{
    font-size: 32px;
    margin: 0;
}
.subheaderMain{
    font-weight: 600;
    white-space: nowrap;
}
.subheaderSecondary{
    opacity: 0.6;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    max-width: calc(100vw - 675px);
    width: 100%;
}