@font-face {
  font-family: 'SF-UI-Text-Regular';
  src: url('SF-UI-Text-Regular.ttf') format('truetype'); /* Chrome 4+, Firefox 3.5, Opera 10+, Safari 3—5 */
}
html, body, #root{
  height: 100%;
}
#root{
  display: flex;
  flex-direction: column;
}
:root {
  --header-bg-color: #343A40;
}
.mar-bottom-12{
  margin: 0 0 12px;
}
.lh-36{
  line-height: 36px;
}
body {
  margin-right: calc(-1 * (100vw - 100%)); /*prevent content movement when scrollbar appears*/
  overflow-x: hidden;
  font-family: 'SF-UI-Text-Regular';
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: var(--bs-dark);
}
path{
  transition: .15s;
}
.disabled-btn {
  pointer-events: none;
  cursor: default;
}
.disabled-link{
  color: rgba(0,0,0,.4);
}
.warning-message~.invalid-feedback{
  display: none;
}
.warning-message{
  width: 100%;
  margin-top: 0.25rem;
  font-size: .875em;
  color: var(--bs-red);
}
.fixed-toast{
  position: fixed !important;
  padding: 20px;
}
input[disabled] ~ button.react-datepicker__close-icon,
button.react-datepicker__close-icon.disabled{
  pointer-events: none;
  cursor: default;
}
.btn-outline-primary:active:not(:hover) > svg > path,
.btn-outline-primary:focus:not(:hover) > svg > path{
  fill: white;
}
.navigation-link.active{
  font-weight: bold;
}
.mainContent{
  padding: 0 60px;
  display: flex;
  flex-direction: column;
}
.mainContent:not(.fade-exit){
  flex: 1 0 auto;
}
.mainContent.fade-exit,
.mainContent.fade-active-exit{
  height: 0;
  overflow: hidden;
}

/*******Transition********/
.sales-enter {
  opacity: 0;
}
.sales-enter-active {
  opacity: 1;
  transition: opacity 500ms ease-in;
}
.sales-exit {
  opacity: 1;
}
.sales-exit-active {
  opacity: 0;
  transition: opacity 500ms ease-in;
}

/********* Table ****************/
table {
  table-layout: fixed;
}
tbody, td, tfoot, th, thead, tr{
  border-style: solid none;
}
tr td{
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
th:first-child,
td:first-child {
  width: 30vw;
}
table.table.table-bordered{
  margin: 12px 0;
}
table.table.table-bordered>thead{
  background: var(--bs-light);
}
.table.table-bordered>thead>tr>th{
  padding: 16px 20px;
}
.table.table-bordered>thead>tr>td::first-letter{
  text-transform: uppercase;
}
.table.table-bordered>tbody>tr>td{
  padding: 32px 10px;
  vertical-align: middle;
}
.table.table-bordered>tbody{
  border-top: none;
}
.title-status-block{
  display: flex;
  gap: 20px;
  align-items: center;
}
.status-wrapper{
  display: flex;
  padding: 4px 16px;
  width: fit-content;
  height: fit-content;
  border-radius: 24px;
}
.status-wrapper.onreview{
  background: var(--bg-status-review);
}
.status-wrapper.ready{
  background: var(--bg-status-ready);
}
.status-wrapper.active{
  background: var(--bg-status-active);
}
.datesBlock{
  display: flex;
  align-items: center;
  gap: 6px;
}
/*****************************************/
/****************** Header ***************/
header{
  height: 75px;
  /*height: 100%;*/
  background: var(--header-bg-color);
  display: flex;
  padding: 20px 60px;
  justify-content: space-between;
}
label.tabName{
  color: var(--header-text-color);
  margin: 0 16px;
  font-weight: 600;
  font-size: 20px;
}
hr.horizontal-line{
  border: none;
  color: var(--bs-gray);
  background-color: var(--bs-gray);
  height: 1px;
  opacity: 1;
  margin: 6px 0;
}
.dropdown button.user-dropdown,
.dropdown.show button.user-dropdown{
  width: auto;
  background: transparent;
  color: #fff;
  border: none;
}
.dropdown button.user-dropdown,
.dropdown.show button.user-dropdown:focus,
.dropdown button.user-dropdown:active:focus{
  box-shadow: none;
}
.dropdown-toggle[aria-expanded="true"]:after {
  transform: rotate(180deg);
}
.dropdown-toggle:after {
  transition: 0.2s;
  margin-left: 12px;
}
.dropdown-user-button{
  display: flex;
  justify-content: space-between
}
div.dropdown-menu{
  min-width: 240px;
}
/**************** Form ************************/
.form-control{
  max-height: 48px;
}
.datepicker-wrapper > label.form-label{
  margin: 4px 0;
  font-size: 16px;
}
.form-label{
  margin-bottom: 6px;
  display: flex;
  align-items: center;
}
.form-label.required:after,
.form-title.required:after{
  content: ' *';
  color: red;
}
.form-control.non-editable-mode{
  background: var(--header-text-color);
  pointer-events: none;
}
/**************** Games Page ***************/
.newtest-left-block,
.newinapp-left-block{
  width: 100%;
  max-width: 1280px;
}
.newtest-left-block{
  display: flex;
  flex-direction: column;
  gap: 40px;
}
.newinapp-left-block{
  display: flex;
  flex-direction: column;
  gap: 32px;
}
.newinapp-section{
  padding: 32px 20px;
  border: 1px solid var(--bs-gray);
  border-radius: 8px;
  background: var(--block-background-gray);
}
.form-title{
  font-weight: 700;
  font-size: 24px;
  margin-bottom: 24px;
}
.form-title.subtitle{
  font-size: 20px;
  margin-bottom: 2px;
}
.innap-body{
  margin-top: 30px;
  display: flex;
  gap: 32px;
  padding: 0 20px;
}
button.dropdown-toggle{
  width: 240px;
  color: var(--bs-dark);
  background: var(--bs-white);
  border-color: #CFD4D9;
}
.info-icon-block{
  position: absolute;
  right: 0;
  top: 0;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 11px;
}
.info-icon-block svg{
  cursor: pointer;
}
.overlay-block{
  position: relative;
}
button.addRemoveSalesBtn:hover{
  background-color: transparent;
  color: var(--bs-blue);
}
/****************** Inapp Page *************/
.action-block{
  width: 90%;
  max-width: 1320px;
  padding: 32px 40px;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  gap: 20px;
}
button.action-block-btn{
  border: none;
}
button.action-block-btn.submit{
  padding: 12px 100px;
}
button.action-block-btn.cancel{
  padding: 12px 30px;
}
button.action-block-btn.cancel:hover{
  background: none;
  color: var(--bs-blue);
}
.inner-action-block{
  display: flex;
  gap: 20px;
}
/****************** Games List *************/
.list-block{
  flex: 1 0 auto;
  display: flex;
  flex-direction: column;
  position: relative;
}
.pagination-wrapper{
  display: flex;
  justify-content: space-between;
  padding: 24px 0 42px;
}
.box_pagination_info{
  display: flex;
  align-items: center;
}
.box_tbl_list{
  flex: 1 0 auto;
}
.table-wrapper{
  margin-top: 11px;
}
.table-wrapper,
div.tab-content,
div.tab-pane.active{
  display: flex;
  flex-direction: column;
  flex: 1 0 auto;
}
table.table.table-bordered>tbody,
table.table>:not(:first-child){
  border-top: 1px solid var(--bs-gray);
}
/****************** Title wrapper *************/
.title-wrapper{
  display: flex;
  align-items: center;
  padding: 10px 20px;
}
/***************** Inapps List ****************/
.inapp-header-block{
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 20px;
}
.actions-block-inapps{
  display: flex;
  gap: 20px;
}
.add-new-inapp-btn{
  display: flex;
  align-items: center;
  gap: 10px;
}
button.additional-styles-light,
button.additional-styles-light:hover,
button.additional-styles-light:active,
button.additional-styles-light:focus{
  color: var(--bs-blue);
  background: transparent;
  border: none;
}
.gamesLink{
  text-decoration: none;
}
.gamesLink>li{
  height: 80px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.gamesLink>li:hover,
.gamesLink>li:active{
  background-color: var(--bs-light);
  border-top: 1px solid var(--bs-gray);
  border-bottom: 1px solid var(--bs-gray);
}
.gamesLink>li>.linkBlock{
  color: var(--bs-blue);
}
.gamesLink>li>.titleBlock{
  color: var(--bs-dark);
  display: flex;
  flex-wrap: wrap;
  white-space: pre-wrap;
}


.alert-enter {
  opacity: 0;
  transform: scale(0.9);
}
.alert-enter-active {
  opacity: 1;
  transform: translateX(0);
  transition: opacity 300ms, transform 300ms;
}
.alert-exit {
  opacity: 1;
}
.alert-exit-active {
  opacity: 0;
  transform: scale(0.9);
  transition: opacity 300ms, transform 300ms;
}
input.form-control-inapps.is-invalid{
  padding-right: 30px;
  margin-right: calc(25vw - 15px);
}
div.overlay-block.is-invalid > .info-icon-block{
  display: none;
}
/*div.form-check{*/
/*  width: 50%;*/
/*}*/
.error-page-wrapper{
  display: flex;
  height: calc(100% - 75px);
  gap: 16px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.error-code{
  font-weight: 600;
  font-size: 32px;
}
.error-text{
  color: var(--symbol-gray);
}

/**************************************/

.fade-appear,
.fade-enter {
  opacity: 0;
  transform: translateY(10px);
}
.fade-appear-active,
.fade-enter-active {
  transition: 0.3s ease-in;
  transform: translateY(0px);
  opacity: 1;
}

.fade-exit {
  transition: 0.3s ease-out;
  transform: translateY(0px);
  opacity: 1;
}

.fade-exit-active {
  opacity: 0;
  transform: translateY(10px);
}
/*.page-main {*/
/*  position: relative;*/
/*  max-width: 600px;*/
/*  margin: 0px auto;*/
/*}*/

.alert-enter {
  opacity: 0;
  transform: scale(0.9);
}
.alert-enter-active {
  opacity: 1;
  transform: translateX(0);
  transition: opacity 300ms, transform 300ms;
}
.alert-exit {
  opacity: 1;
}
.alert-exit-active {
  opacity: 0;
  transform: scale(0.9);
  transition: opacity 300ms, transform 300ms;
}
/************ CARDS **********/
.cards-block{
  display: flex;
  gap: 32px;
  padding: 24px 20px;
}
.testBlock{
  padding: 32px 20px;
  background: var(--header-text-color-rgb);
  border-radius: 8px;
  border: 1px solid var(--bs-gray);

  display: flex;
  flex-direction: column;
  gap: 24px;
}
label.grid-item{
  /*margin: auto 8px;*/
  margin: 6px 8px;
  text-transform: uppercase;
  align-items: flex-start;
}
.localization-grid{
  display: grid;
  grid-template-columns: auto 37% 37% 4%;
  gap: 32px 16px;
  margin: 10px;
}
.grid-item.sale-title{
  max-height: 14px;
}
.deleteLocaleBtn{
  height: fit-content;
}

.list-container.removed-item {
  animation: removed-item-animation .8s cubic-bezier(.65,-0.02,.72,.29);
}
.list-container.restored-item {
  animation:
          openspace .3s ease forwards,
          restored-item-animation .5s .3s cubic-bezier(.14,.25,.52,1.56) forwards;
}
.locale-actions-block{
  display: flex;
  gap: 16px;
}