.production-wrapper{
    display: flex;
    align-items: center;
    gap: 8px;
}
.hide-long-text{
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 25vw;
    display: block;
}