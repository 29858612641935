/*.lineGroup {*/
/*    display: flex;*/
/*    justify-content: space-between;*/
/*    align-items: center;*/
/*    margin: 16px 0;*/
/*}*/

.formControl {
    max-width: 348px;
    /*margin-right: 25vw;*/
}
textarea.formControl{
    overflow: hidden;
    line-height: normal;
    resize: none;
    min-height: 92px;
    max-width: 100%;
}

/********** AB Tests Page *************/
.labelBold {
    width: 100%;
    font-weight: 700;
    font-size: 24px;
    margin: 0 0 20px;
}

.formGroup {
    display: flex;
    flex-direction: column;
    width: 100%;
}

.formGroup__leftBlock {
    width: 80%;
    max-width: 652px;
}

.subLabel {
    font-size: 14px;
    margin: 0;
    color: var(--symbol-gray);
}

.fileInputGroup {
    width: calc(80% - 22px);
    max-width: 652px;
}
.inputSignGroup{
    display: flex;
    max-width: 208px;
}
.inputWithSign{
    border-bottom-right-radius: 0;
    border-top-right-radius: 0;
}
.currency-tier-label{
    margin: 0;
}
.sales-grid{
    display: grid;
    grid-template-columns: auto auto auto;
    gap: 16px;
    margin: 22px 2px 0px;
    width: fit-content;
}
.sales-grid :global(.invalid-feedback){
    white-space: pre-line;
    max-width: 208px;
}