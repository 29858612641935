.header--fallback {
    font-weight: 600;
    margin: 32px 0 12px;
}
.description--fallback{
    margin: 0;
}
.go-ahead--fallback {
    font-size: 20px;
    padding: 12px 32px;
    margin-top: 32px;
}
.fallback--wrapper {
    display: flex;
    width: 100%;
    height: 100%;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}