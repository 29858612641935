:root {
    --header-bg-color: #343A40;
    --header-text-color: #E9ECEF;
    --header-text-color-rgb: rgba(233, 236, 239, 0.9);
    --block-background-gray: #F8F9FA;
    --bs-gray: #DEE2E6;
    --bs-dark: #212529;
    --bs-white: #FFFFFF;
    --bs-blue: #0D6EFD;
    --bs-green: #198754;
    --bs-light: #F8F9FA;
    --bs-red: #dc3545;
    --gray-border: #CFD4D9;
    --bg-button-gray:#EAEBEF;
    --symbol-gray: #6C757D;
    --track-bg: #CED4DA;
    --border-gray: #DFE2E6;
    --bg-loading-gray: #D9D9D9;
    --bg-status-review: rgba(13, 110, 253, 0.37);
    --bg-status-ready: rgba(244, 185, 8, 0.5);
    --bg-status-active: rgba(25, 135, 84, 0.4);
    --bg-sales: rgba(233, 236, 239, 0.6);
    --bs-gray-border: #ced4da;
}