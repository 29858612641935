.scrollButton{
    position: fixed;
    width: 56px;
    height: 56px;
    right: 40px;
    bottom: 40px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    background: var(--bs-white);
    border: 1px solid #CED4DA;
    box-shadow: 0px 4px 6px rgb(0 0 0 / 15%);
    transition: opacity .5s;
}